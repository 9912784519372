<template>
	<div class="login">
		<v-sheet rounded class="login-container">
			<v-container>
					<br>
					<h2 class="login-heading">Awaiting Sign In</h2>
			</v-container>
			
		</v-sheet>
	</div>
</template>


<script>
export default {
	name: "Login",
	methods: {
        async SignIn() {
            this.$store.
                dispatch("signIn")
                .then(() => {
                    this.$router.push('/');
                })
                .catch(error => {
                    console.error(`error: ${error}`);
                });
        },
	},
    mounted() {
        this.SignIn();
    },
};
</script>

<style lang="scss" scoped>
.login {
	position: relative;
	width: 100%;
	height: 100%;

	&-container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 3rem 2.5rem;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	}

	&-heading {
		font-size: 3rem;
		text-align: center;
		margin-bottom: 2rem;
	}

	&-form {
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		margin-bottom: 4rem;

		.col {
			margin-bottom: 1.5rem;
		}

		.v-btn {
			font-size: 1.4rem;
			margin: 1rem 0;
		}

		a {
			font-size: 1.4rem;
			text-decoration: none;
			color: $color-gray-dark;
			transition: all 0.4s;

			&:hover {
				color: $color-primary;
			}

			&:focus {
				outline: none;
			}
		}
	}

	&-failed {
		color: $color-red;
		font-size: 1.4rem;
	}
}

::v-deep {
	.v-label {
		font-size: 1.4rem;
	}

	.v-input--selection-controls {
		margin-top: 0;
		padding-bottom: 4px;
	}
}
</style>
